import _export from "../internals/export";
import _getBuiltIn from "../internals/get-built-in";
import _functionApply from "../internals/function-apply";
import _functionBind from "../internals/function-bind";
import _aConstructor from "../internals/a-constructor";
import _anObject from "../internals/an-object";
import _isObject from "../internals/is-object";
import _objectCreate from "../internals/object-create";
import _fails from "../internals/fails";
var $ = _export;
var getBuiltIn = _getBuiltIn;
var apply = _functionApply;
var bind = _functionBind;
var aConstructor = _aConstructor;
var anObject = _anObject;
var isObject = _isObject;
var create = _objectCreate;
var fails = _fails;
var nativeConstruct = getBuiltIn("Reflect", "construct");
var ObjectPrototype = Object.prototype;
var push = [].push;

// `Reflect.construct` method
// https://tc39.es/ecma262/#sec-reflect.construct
// MS Edge supports only 2 arguments and argumentsList argument is optional
// FF Nightly sets third argument as `new.target`, but does not create `this` from it
var NEW_TARGET_BUG = fails(function () {
  function F() {/* empty */}
  return !(nativeConstruct(function () {/* empty */}, [], F) instanceof F);
});
var ARGS_BUG = !fails(function () {
  nativeConstruct(function () {/* empty */});
});
var FORCED = NEW_TARGET_BUG || ARGS_BUG;
$({
  target: "Reflect",
  stat: true,
  forced: FORCED,
  sham: FORCED
}, {
  construct: function construct(Target, args /* , newTarget */) {
    aConstructor(Target);
    anObject(args);
    var newTarget = arguments.length < 3 ? Target : aConstructor(arguments[2]);
    if (ARGS_BUG && !NEW_TARGET_BUG) return nativeConstruct(Target, args, newTarget);
    if (Target === newTarget) {
      // w/o altered newTarget, optimization for 0-4 arguments
      switch (args.length) {
        case 0:
          return new Target();
        case 1:
          return new Target(args[0]);
        case 2:
          return new Target(args[0], args[1]);
        case 3:
          return new Target(args[0], args[1], args[2]);
        case 4:
          return new Target(args[0], args[1], args[2], args[3]);
      }
      // w/o altered newTarget, lot of arguments case
      var $args = [null];
      apply(push, $args, args);
      return new (apply(bind, Target, $args))();
    }
    // with altered newTarget, not support built-in constructors
    var proto = newTarget.prototype;
    var instance = create(isObject(proto) ? proto : ObjectPrototype);
    var result = apply(Target, instance, args);
    return isObject(result) ? result : instance;
  }
});
export default {};